import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiClient from "../../methods/api/apiClient";
import "./style.scss";
import { ToastsStore } from "react-toasts";
import loader from "../../methods/loader";
import userTableModel from "../../models/table.model";
import Html from "./Html";
import { userType } from "../../models/type.model";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import environment from "../../environment";
import { toast } from "react-toastify";
import addressModel from "../../models/address.model";

const Loads2 = (p) => {
  const [OriginDestFilters, setOriginDestFilters] = useState({})

  const user = useSelector((state) => state.user);
  const [DataLength, setDataLength] = useState(0);
  const [RefreshConnetion,setRefreshConnetion]=useState("")
  const [ShowDeleteModal, setShowDeleteModal] = useState("none");
  const [ShowActiveModal, setShowActiveModal] = useState("none");
  const [loaderr, setLoaderr] = useState(false);
  const { role ,id } = useParams();
  const searchState = useSelector((state) => state.search);
  const [filters, setFilter] = useState({
    page: 1,
    count: 10,
    search: "",
    role: role || "",
    isDeleted: false,
    board_id: "",
  });

  const [createdDate, setCreated] = useState(0);
  const [TAB, SETTAB] = useState("list");
  const [data, setData] = useState([]);
  const [tab, setTab] = useState("list");
  const [total, setTotal] = useState(0);

  const [loaging, setLoader] = useState(true);
  const [tableCols, setTableCols] = useState([]);
  const [form, setform] = useState(userType);
  const [roles, setRoles] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState("");
  const [ExpiredDate, setExpiredDate] = useState(0);
  const [LeftDays, setLeftDays] = useState(0);
  const [Hour, setHour] = useState(0);
  const history = useHistory();
  const [Header, setHeader] = useState("Loads");

  const url = window.location.pathname;
  const CheckParamsPath = () => {
    switch (url) {
      case "/loads":
        return { key: "Loads", status: "" };
        break;
      case "/delivered-loads":
        return { key: "Delivered Loads", status: "delivered" };
        break;
      case "/pendingPickup-loads":
        return { key: "Pending Pickup Loads", status: "pending" };
        break;
      case "/pickedUp-loads":
        return { key: " PickedUp Loads", shipment_status: "pickedup" };
        break;
      case "/awarded-loads":
        return { key: "Awarded Loads", status: "awarded" };
        break;
      case "/intransit-loads":
        return { key: "In-Transit Loads", status: "in_transit" };
        break;
      case "/prebook-loads":
        return { key: "Prebook Loads", status: "PREBOOK" };
        break;
      case "/usps-loads":
        return { key: "USPS Loads", status: "USPS" };
        break;
      case "/rfps-loads":
        return { key: "RFPS Loads", status: "RFPS" };
        break;
      case "/available-loads":
        return { key: "Available Bids", status: "" };

      default:
        return "Loads";
    }
  };
  useEffect(() => {
    if (user && user.loggedIn) {
      setFilter({ ...filters, search: searchState.data, role });
      {
        TAB == "list"
          ? getData({ search: searchState.data, role, page: 1 })
          : getExpiredData({ search: searchState.data, role, page: 1 });
      }
    }
  }, [searchState, role, url]);

  const uTableCols = () => {
    let exp = [];
    if (tableCols) exp = tableCols;
    let value = [];
    userTableModel.list.map((itm) => {
      if (itm != exp.find((it) => it.key == itm.key)) {
        value.push(itm);
      }
    });
    return value;
  };

  const addCol = (itm) => {
    setTableCols([...tableCols, itm]);
  };

  useEffect(() => {
    getData({ ...filters, isExpired: false });
  }, [url]);

  const addressResult = async (e) => {
    let address = {};
    if (e.place) {
      address = await addressModel.getAddress(e.place);
    }
  
    setFilter({
      ...filters,
      origin_location_street: e.value,
      origin_location_country: address.country || "",
      origin_location_city: address.city || "",
      origin_location_state: address.state || "",
      origin_location_postal_code: address.zipcode || "",
      // lat: `${address.lat}` || '',
      // lng: `${address.lng}` || ''
    });
    {
      TAB == "list"
        ? getData({ origin_location_city: address.city })
        : getExpiredData({ origin_location_city: address.city });
    }
  
  };
  const removeCol = (index) => {
    let exp = tableCols;
    exp.splice(index, 1);
    setTableCols([...exp]);
  };

  const GetLoadsFromEmail = () => {
    loader(true);

    ApiClient.post("scrap-email").then((res) => {
      if (res.success) {
        {
          TAB == "list" ? getData() : getExpiredData();
        }
      }
      loader(false);
    });
  };

  const GetRefreshConnetion = () => {
    loader(true);
    ApiClient.post("update-manual-subscription").then((res) => {
      if (res.success) {
        {
          // document.getElementById("closemodel").click()
          document.getElementById("refreshconnection").click()
          setRefreshConnetion(res?.message)
          // toast.success(res.message);
          TAB == "list" ? getData() : getExpiredData();
        }
      }
      loader(false);
    });
  };

  const getData = (p = {}) => {
    setLoader(true);
    setLoaderr(true)
    let filter = {
      ...filters,
     ...OriginDestFilters,
     board_id:id, 
      ...p,   
    
      // status: CheckParamsPath()?.status,
      // request_status: CheckParamsPath(),
    };
     filter={
      ...filter,
      destination_location_state:filter?.destination_location_state?.toString(),
      destination_location_city:filter?.destination_location_city?.toString(),
      origin_location_city:  filter?.origin_location_city?.toString(),
      origin_location_state:filter?.origin_location_state?.toString(),
    }
    if (CheckParamsPath()?.status == "pending") {
      filter = { ...filter, request_status: "awarded",shipment_status: "pending" };
    }else if (CheckParamsPath()?.shipment_status == "pickedup") {
      filter = { ...filter, shipment_status: "pickedup" };
    } else if (CheckParamsPath()?.status == "delivered") {
      filter = { ...filter, shipment_status: "delivered" };
    } else if (CheckParamsPath()?.status == "in_transit") {
      filter = { ...filter, shipment_status: "in_transit" };
    } else if (id) {
      filter = { ...filter, board_id: id};
    } 
    // else if (CheckParamsPath()?.status == "RFPS") {
    //   filter = { ...filter, board_id: environment.rfpsId };
    // } else if (CheckParamsPath()?.status == "USPS") {
    //   filter = { ...filter, board_id: environment.uspsId };
    // } else {
    //   filter = { ...filter, status: CheckParamsPath()?.status };
    //   // request_status: CheckParamsPath(),}
    // }
    let url = "filter-loads";

    ApiClient.get(url, filter).then((res) => {
      if (res.success) {
        if (res?.data?.length == 0) {
          toast.warn("There is no new load to fetch ");
        }
        const data = res?.data?.data;
        console.log(data,"===============")
        setData(data);
        setDataLength(data.length);
        setTotal(res?.data?.total);
        setCreated(res?.data?.createdAt);
        setExpiredDate(res?.data?.board_id?.expiration_date);

      }
      setLoader(false);
      setLoaderr(false)
    });
  };

  const getExpiredData = (p = {}) => {
    setLoader(true);
    setLoaderr(true)
    let filter = {
      ...filters,
      ...OriginDestFilters,
      board_id:id, 
      ...p,   
     
      isExpired: true,  
    };
    filter={
      ...filter,
      destination_location_state:filter?.destination_location_state?.toString(),
      destination_location_city:filter?.destination_location_city?.toString(),
      origin_location_city:  filter?.origin_location_city?.toString(),
      origin_location_state:filter?.origin_location_state?.toString(),
    }

    if (CheckParamsPath()?.status == "pending") {
      filter = { ...filter, request_status: "awarded" };
    }else if (CheckParamsPath()?.shipment_status == "pickedup") {
      filter = { ...filter, shipment_status: "pickedup" };
    } else if (CheckParamsPath()?.status == "delivered") {
      filter = { ...filter, shipment_status: "delivered" };
    } else if (CheckParamsPath()?.status == "in_transit") {
      filter = { ...filter, shipment_status: "in_transit" };
    } else if (id) {
      filter = { ...filter, board_id: id };
    } 
    // else if (CheckParamsPath()?.status == "RFPS") {
    //   filter = { ...filter, board_id: environment.rfpsId };
    // } else if (CheckParamsPath()?.status == "USPS") {
    //   filter = { ...filter, board_id: environment.uspsId };
    // } else {
    //   filter = { ...filter, status: CheckParamsPath()?.status };
    //   // request_status: CheckParamsPath(),}
    // }
    let url = "filter-loads";

    ApiClient.get(url, filter).then((res) => {
      if (res.success) {
        if (res?.data?.length == 0) {
          toast.warn("There is no new load to fetch ");
        }
        const data = res?.data?.data;
        setData(data);
        setDataLength(data.length);
        setTotal(res?.data?.total);
        setCreated(res?.data?.createdAt);
        setExpiredDate(res?.data?.board_id?.expiration_date);
      }
      setLoader(false);
      setLoaderr(false)
    });
  };

  const Timeleft = () => {
    let Created = new Date(ExpiredDate);

    let expired = new Date();

    let Total = Created.getTime() - expired.getTime();
    let LeftDay = Math.round(Total / (1000 * 3600 * 24));
    setLeftDays(LeftDay);
    // Timeleft()
  };
  const StartTimer = () => {
    let Created = new Date(ExpiredDate);
    let expired = new Date();
    let Total = Created.getTime() - expired.getTime();

    const days = Math.floor((Total / (1000 * 60 * 60)) * 24);

    const hours = Math.floor(Total / (1000 * 60 * 60));
    const minutes = Math.floor((Total % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((Total % (1000 * 60)) / 1000);
    setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
    setHour(hours);
  };
  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      StartTimer();
      Timeleft();
    }, 1000);

    return () => clearInterval(interval);
  }, [timeRemaining]);

  useEffect(() => {
    Timeleft();

    let UpdatedDate = new Date(ExpiredDate);
    // setDate(UpdatedDate)
  }, [data]);

  const ChangeFilter = (e) => {
    setFilter(e);
    {
      TAB == "list" ? getData(e) : getExpiredData(e);
    }
  };

  const clear = () => {
    setFilter({ ...filters, search: "" });
    {
      TAB == "list" ? getData({ search: "" }) : getExpiredData({ search: "" });
    }
  };

  const getRoles = () => {
    ApiClient.get("roles/listing", { status: "active" }).then((res) => {
      if (res.success) {
        if (user.role && user.role.id == "64e5d268c27faa1d3ef35503") {
          const data = res.data;
          const newarray = [];
          data.map((item, index) => {
            if (item.id != "64e5d268c27faa1d3ef35503") {
              newarray.push(item);
            }
          });
          setRoles(newarray);
        } else {
          setRoles(res.data);
        }
      }
    });
  };

  const deleteItem = (id) => {
    // if (window.confirm("Do you want to delete this")) {
    loader(true);
    setLoaderr(true)
    ApiClient.delete(`delete?model=loadmanagement&id=${id}`).then((res) => {
      if (res.success) {
        toast.success(res.message);
        setShowDeleteModal("none");
        if (DataLength == 0) {
          setFilter({ ...filters, search: "", page: 1 });
          {
            TAB == "list"
              ? getData({ search: "", page: 1 })
              : getExpiredData({ search: "", page: 1 });
          }
        } else {
          clear();
        }
      }
      loader(false);
      setLoaderr(false)
    });
    // }
  };

  const pageChange = (e) => {
    setFilter({ ...filters, page: e });
    {
      TAB == "list" ? getData({ page: e }) : getExpiredData({ page: e });
    }
  };

  const modalClosed = () => {
    setFilter({ ...filters, page: 1 });
    {
      TAB == "list" ? getData({ page: 1 }) : getExpiredData({ page: 1 });
    }
  };

  const modalResult = (e) => {
    // console.log("modalResult", e);
    modalClosed();
  };

  const openModal = (itm) => {
    let extra = new Date().getTime();
    setform({ ...itm, extra, role });
    document.getElementById("openuserModal").click();
  };

  const ChangeRole = (e) => {
    setFilter({ ...filters, role: e, page: 1 });
    {
      TAB == "list"
        ? getData({ role: e, page: 1 })
        : getExpiredData({ role: e, page: 1 });
    }
  };
  const ChangeStatus = (e) => {
    setFilter({ ...filters, status: e, page: 1 });
    {
      TAB == "list"
        ? getData({ status: e, page: 1 })
        : getExpiredData({ status: e, page: 1 });
    }
  };
  const ChangeDocumentStatus = (e) => {
    setFilter({ ...filters, isVerifiedDocument: e, page: 1 });
    {
      TAB == "list"
        ? getData({ isVerifiedDocument: e, page: 1 })
        : getExpiredData({ isVerifiedDocument: e, page: 1 });
    }
  };
  const exportCsv = () => {
    loader(true);
    setLoaderr(true)
    ApiClient.get("user/csv").then((res) => {
      if (res.success) {
        let url = res.path;
        let downloadAnchor = document.getElementById("downloadJS");
        downloadAnchor.href = url;
        downloadAnchor.click();
      }
      loader(false);
      setLoaderr(false)
    });
  };

  const colClick = (col, itm) => {
    if (col.key == "healthClinicId") {
    }
  };

  const statusChange = (itm) => {
    let modal = "loadmanagement";
    let status = "active";
    if (itm.status == "active") status = "deactive";

    loader(true);
    setLoaderr(true)
    ApiClient.put(
      `change/status?model=loadmanagement&id=${itm?.id}&status=${status}`
    ).then((res) => {
      if (res.success) {
        {
          TAB == "list" ? getData() : getExpiredData();
        }
        toast.success(
          ` Load ${status == "active" ? "Enabled" : "Disabled"} Successfully`
        );
        setShowActiveModal("none");
      }
      loader(false);
      setLoaderr(false)
    });
  };

  const blockunblock = (itm) => {
    if (
      window.confirm(
        `Do you want to ${!itm.isBlock ? "Block" : "Un-block"} this user`
      )
    ) {
      loader(true);
      setLoaderr(true)
      ApiClient.put(`edit-profile`, {
        id: itm.id,
        isBlock: itm.isBlock ? false : true,
      }).then((res) => {
        if (res.success) {
          {
            TAB == "list" ? getData() : getExpiredData();
          }
        }
        loader(false);
        setLoaderr(false)
      });
    }
  };
  const deleteLoad = (id) => {
    if (window.confirm("Do you want to delete this ?")) {
      loader(true);
      setLoaderr(true)
      ApiClient.delete(`delete?model=loadmanagement&id=${id}`).then((res) => {
        if (res.success) {
          toast.success(res?.message);
          {
            TAB == "list" ? getData() : getExpiredData();
          }
        }
        loader(false);
        setLoaderr(false)
      });
    }
  };
  const view = (id) => {
    history.push(`/loads/view/${id}`);
  };

  const edit = (id) => {
    let url = `/loads/edit/${id}`;
    if (role) url = `/users/${role}/edit/${id}`;
    history.push(url);
  };

  const add = () => {
    let url = `/loads/add`;
    if (role) url = `/users/${role}/add`;
    history.push(url);
  };

  const tabChange = (tab) => {
    setTab(tab);
  };

  const exportfun = async () => {
    const token = await localStorage.getItem("token");
    const req = await axios({
      method: "get",
      url: `${environment.api}api/export/user`,
      responseType: "blob",
      body: { token: token },
    });
    var blob = new Blob([req.data], {});
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Users.xlsx`;
    link.click();
  };

  const reset = () => {
    let filter = {
      status: "",
      role: "",
      page: 1,
    };
    setFilter({ ...filters, ...filter });
    {
      TAB == "list" ? getData({ ...filter }) : getExpiredData({ ...filter });
    }

    // dispatch(search_success(''))
  };
  const Handlefilter = (e) => {
    const value = e.target.value;
    setFilter({ ...filters, [e.target.name]: value });
    {
      TAB == "list"
        ? getData({ [e.target.name]: value })
        : getExpiredData({ [e.target.name]: value });
    }
  };

  const isAllow = (key = "") => {
    return true;
    let permissions = user.role?.permissions;
    let value = permissions?.[key];
    if (user?.role?.id == environment.adminRoleId) value = true;
    return value;
  };
  const filter = (p = {}) => {
    setFilter({ ...filters, ...p });
    {
      TAB == "list"
        ? getData({ ...p, page: filters?.page })
        : getExpiredData({ ...p, page: filters?.page });
    }
  };
  const sorting = (key, i) => {
    // getData({sortBy})

    filter({ sortBy: `${key} ${i}`, sorder: i });
    // getData({ sortBy: key, sorder: i });
  };
  return (
    <>
      <Html
        user={user}
        colClick={colClick}
        exportfun={exportfun}
        isAllow={isAllow}
        tabChange={tabChange}
        tab={tab}
        reset={reset}
        add={add}
        roles={roles}
        view={view}
        edit={edit}
        role={role}
        ChangeRole={ChangeRole}
        ChangeStatus={ChangeStatus}
        openModal={openModal}
        pageChange={pageChange}
        addCol={addCol}
        deleteItem={deleteItem}
        exportCsv={exportCsv}
        uTableCols={uTableCols}
        removeCol={removeCol}
        filters={filters}
        ChangeDocumentStatus={ChangeDocumentStatus}
        setShowActiveModal={setShowActiveModal}
        ShowActiveModal={ShowActiveModal}
        tableCols={tableCols}
        loaging={loaging}
        data={data}
        ShowDeleteModal={ShowDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        total={total}
        statusChange={statusChange}
        blockunblock={blockunblock}
        setFilter={setFilter}
        sorting={sorting}
        addressResult={addressResult}
        getData={getData}
        ChangeFilter={ChangeFilter}
        deleteLoad={deleteLoad}
        timeRemaining={timeRemaining}
        ExpiredDate={ExpiredDate}
        GetLoadsFromEmail={GetLoadsFromEmail}
        GetRefreshConnetion={GetRefreshConnetion}
        setRefreshConnetion={setRefreshConnetion}
        RefreshConnetion={RefreshConnetion}
        Handlefilter={Handlefilter}
        getExpiredData={getExpiredData}
        TAB={TAB}
        url={url}
        OriginDestFilters={OriginDestFilters}
        setOriginDestFilters={setOriginDestFilters}
        CheckParamsPath={CheckParamsPath}
        SETTAB={SETTAB}
        loaderr={loaderr}
      />
    </>
  );
};

export default Loads2;
