import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import "./style.scss";
import rolesModel from "../../models/roles.model";
import methodModel from "../../methods/methods";
import datepipeModel from "../../models/datepipemodel";
import environment from "../../environment";
import { useHistory, useParams } from "react-router-dom";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import Switch from "react-switch";
import CommonDeleteModal from "../CommonDelete&ActiveModal/CommonDeleteModal";
import CommonActiveModal from "../CommonDelete&ActiveModal/CommonActiveModal";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import ApiClient from "../../methods/api/apiClient";
import SelectDropdown from "../../components/common/SelectDropdown";
import MultiSelectDropdown from "../../components/common/MultiSelectDropdown";
import moment from "moment";
import CountdownTimer from "./countdoenTimer";
import loader from "../../methods/loader";
import { Col, Tooltip } from "antd";
import { IoHammerOutline } from "react-icons/io5";
import { LuCircleDotDashed } from "react-icons/lu";
import { LuCircleDot } from "react-icons/lu";
import dateFormat from "dateformat";
import { FaAward } from "react-icons/fa6";
import pipeModel from "../../models/pipeModel";
import Layout2 from "../../components/global/layout2";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import axios from "axios";
import Swal from "sweetalert2";
const Html = ({
  view,
  addressResult,
  edit,
  loaderr,
  user,
  ChangeFilter,
  deleteLoad,
  GetLoadsFromEmail,
  GetRefreshConnetion,
  setRefreshConnetion,
  RefreshConnetion,
  reset,
  CheckParamsPath,
  sorting,
  add,
  ChangeStatus,
  openModal,
  statusChange,
  OriginDestFilters,
  setOriginDestFilters,
  pageChange,
  addCol,
  deleteItem,
  filters,
  setFilter,
  blockunblock,
  loaging,
  getData,
  data,
  exportfun,
  roles,
  timeRemaining,
  role,
  ShowActiveModal,
  setShowActiveModal,
  ShowDeleteModal,
  setShowDeleteModal,
  isAllow,
  Handlefilter,
  getExpiredData,
  TAB,
  SETTAB,
  total = { total },
}) => {
  const Navigate = useHistory();
  const { id } = useParams();
  const [Min_rate, setMin_rate] = useState("");
  const [Max_rate, setMax_rate] = useState("");
  const [DeleteId, setDeleteId] = useState("");
  const [setboard, setNewBoard] = useState("Loads");
  const [BoardFilter, setBoardFilter] = useState([]);
  const [removeLoads, setremoveLoads] = useState([]);
  const [boards, setBoards] = useState([]);
  const [boardName, setBoardName] = useState("Loads");
  const latestSliderValue = React.useRef([0, 0]);
  const [AppliedBid, setAppliedBids] = useState([]);
  const [BidDetial, setBidDetails] = useState({});
  const [tab, settab] = useState("best");
  const [bigLoader, setBidLoader] = useState(false);
  const [isToggel, isSetToggel] = useState(false);
  const [viewStatus, setViewStatus] = useState();
  const Columns = JSON.parse(localStorage.getItem("LoadColumn")) || [];
  const url = window.location.pathname;
  const StartTimer = (data) => {
    let Created = new Date(data);
    let expired = new Date();
    let Total = Created.getTime() - expired.getTime();

    const days = Math.floor((Total / (1000 * 60 * 60)) * 24);

    const hours = Math.floor(Total / (1000 * 60 * 60));
    // setHour(hours);

    const minutes = Math.floor((Total % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((Total % (1000 * 60)) / 1000);
    // setTimeRemaining2(`${hours}h ${minutes}m ${seconds}s`);
    // setHour(hours);

    return `${hours}h ${minutes}m ${seconds}s`;
  };
  useEffect(() => {
    setInterval(() => {
      // console.log(StartTimer());
    }, 1000);
  }, []);
  const columns = [
    // "distance",
    "origin_city",
    "origin_state",
    "origin_zip",
    "destination_city",
    "destination_state",
    "destination_zip",
    "load_start_date",
    "load_end_date",
    "avg_rating",
    "total_reviews",
    // "Posted At",
    // "min_rate",
    // "max_rate",
  ];

  const [visibleColumns, setVisibleColumns] = useState(Columns);

  useEffect(() => {
    localStorage.setItem("LoadColumn", JSON.stringify(visibleColumns));
  }, [visibleColumns]);

  const GetBidsUser = (id) => {
    setBidLoader(true);
    ApiClient.get("bids-on-load", { id }).then((res) => {
      if (res.success) {
        setAppliedBids(res?.data?.bids_detail);
        setBidDetails(res?.data);
      }
      setBidLoader(false);
    });
  };
  const handleColumnToggle = (columnName) => {
    // Check if the column is currently visible
    const isColumnVisible = visibleColumns.includes(columnName);

    // Toggle the column visibility
    const updatedColumns = isColumnVisible
      ? visibleColumns.filter((col) => col !== columnName)
      : [...visibleColumns, columnName];

    setVisibleColumns(updatedColumns);
  };

  function replaceUnderscoresWithSpace(inputString) {
    // Use the replace method with a regular expression to replace underscores with spaces
    const resultString = inputString.replace(/_/g, " ");

    return resultString;
  }

  const debouncedHandleSliderChange = debounce((newValues) => {
    const [min, max] = newValues;
    setMin_rate(min);
    setMax_rate(max);

    {
      TAB == "list"
        ? getData({ min_rate: min, max_rate: max })
        : getExpiredData({ min_rate: min, max_rate: max });
    }
  }, 500);

  const handleSliderChange = (newValues) => {
    if (
      JSON.stringify(newValues) === JSON.stringify(latestSliderValue.current)
    ) {
      return;
    }
    latestSliderValue.current = newValues;
    debouncedHandleSliderChange(newValues);
  };

  const Delete = () => {
    deleteItem(DeleteId);
  };

  const [StatusData, setStatusData] = useState({});
  const StatusCh = () => {
    statusChange(StatusData);
  };

  useEffect(() => {
    ApiClient.get("boards?status=active").then((res) => {
      // setBoards(res?.data?.data);
      let arr = res?.data?.data?.map((itm) => {
        return {
          name: `${methodModel.capitalizeFirstLetter(itm?.name)} | expires  ${itm?.expiration_type == "hours"
            ? moment()
              .endOf("hours")
              .add(itm?.expiration_value, "hours")
              .fromNow()
            : itm?.expiration_type == "minutes"
              ? moment()
                .endOf("minutes")
                .add(itm?.expiration_value, "minutes")
                .fromNow()
              : moment()
                .endOf("days")
                .add(itm?.expiration_value, "days")
                .fromNow()
            }`,
          id: itm?.id,
        };
      });
      setBoards(arr);
    });
  }, []);

  useEffect(() => {
    setMin_rate(0);
    setMax_rate(4000);
  }, []);

  const Permission = JSON.parse(localStorage.getItem("permission"));
  const Role = [
    {
      key: "staff",
      name: "Staff",
    },
    {
      key: "carrier",
      name: "Carrier",
    },
  ];
  let ListingData = [];
  if (user?.role == "staff") {
    ListingData = data?.filter((itm) => itm?.id != user?.id);
  } else {
    ListingData = data;
  }

  const ConvertToSortingKeys = (str) => {
    switch (str) {
      case "origin_city":
        return "origin_location_city";
        break;

      case "origin_state":
        return "origin_location_state";
        break;

      case "origin_zip":
        return "origin_location_postal_code";
        break;

      case "destination_city":
        return "destination_location_city";
        break;

      case "destination_state":
        return "destination_location_state";
        break;

      case "destination_zip":
        return "destination_location_postal_code";
        break;

      case "Posted At":
        return "createdAt";
        break;

      default:
        return str;
    }
  };

  const ColumnReturner = (data, value, itm) => {
    switch (data) {
      case "origin_city":
        return itm?.origin_location_city || "--";
        break;
      case "origin_state":
        return itm?.origin_location_state || "--";
        break;
      case "origin_zip":
        return itm?.origin_location_postal_code || "--";
        break;
      case "destination_city":
        return (
          ConvertStringToAbbrivate(itm?.destination_location_city).replace(
            ",",
            " "
          ) || "--"
        );
        break;
      case "destination_state":
        return (
          ConvertStringToAbbrivate(itm?.destination_location_state).replace(
            ", ",
            " "
          ) || "--"
        );
        break;
      case "destination_zip":
        return itm?.destination_location_postal_code || "--";
        break;
      case "load_start_date":
        return datepipeModel.isotodate(value);
        break;
      case "load_end_date":
        return datepipeModel.isotodate(value);
        break;
      case "Posted At":
        return datepipeModel.date(itm?.createdAt);
        break;
      // case "distance":
      //   return itm?.total_distance
      // case "min_rate":
      //   return `$${itm?.min_rate}`;
      //   break;
      // case "max_rate":
      //   return `$${itm?.max_rate}`;
      //   break;
      // case "status":
      //     return <select class="custom-select text-capitalize" disabled={itm?.status !== "pending"} value={itm?.status} onChange={e => statusChange(itm, e.target.value)}>
      //         <option>{itm?.status}</option>
      //         <option value="accepted">Accept</option>
      //         <option value="cancelled">Reject</option>
      //     </select>
      //     break;
      default:
        return pipeModel.number(value) == NaN
          ? value || ""
          : pipeModel.number(value);
        break;
    }
  };

  function findUniqueElements(arr1, arr2) {
    const uniqueInArr1 = arr1.filter((item) => !arr2.includes(item));
    const uniqueInArr2 = arr2.filter((item) => !arr1.includes(item));

    const uniqueElements = [...uniqueInArr1, ...uniqueInArr2];

    return uniqueElements;
  }

  useEffect(() => {
    let JoindID = BoardFilter.join(",");
    {
      TAB == "list"
        ? getData({ ...filters, board_id: JoindID })
        : getExpiredData({ ...filters, board_id: JoindID });
    }
  }, [BoardFilter]);

  const HandleFilter = (data = {}) => {
    TAB == "list"
      ? getData({ ...filters, ...data })
      : getExpiredData({ ...filters, ...data });
  };

  const completeBid = (id) => {
    // if (window.confirm("Do you want to delete this")) {
    loader(true);
    ApiClient.put(`bid?id=${id}&status=accepted`).then((res) => {
      if (res.success) {
        toast.success(res.message);
        // setShowAcceptModal("none")
        document.getElementById("CloseBidViewModel").click();
        {
          TAB == "list"
            ? getData({ search: "", page: 1 })
            : getExpiredData({ search: "", page: 1 });
        }
      }
      loader(false);
    });

    // }
  };

  const ConvertStringToAbbrivate = (str) => {

    let key = str?.includes(" ");
    if (key) {

      let extractletter = str
        ?.split(" ")
        ?.map((word) => methodModel.capitalizeFirstLetter(word[0]));

      return extractletter?.join("");
    } else {
      return str || "--";
    }
  };

  const rejectBid = (id) => {
    // if (window.confirm("Do you want to delete this")) {
    loader(true);
    ApiClient.put(`bid?id=${id}&status=rejected`).then((res) => {
      if (res.success) {
        toast.success(res.message);
        // setShowRejectModal("none")
        document.getElementById("CloseBidViewModel").click();
        {
          TAB == "list"
            ? getData({ search: "", page: 1 })
            : getExpiredData({ search: "", page: 1 });
        }
      }
      loader(false);
    });
    // }
  };

  const [ratingForm, setRatingForm] = useState({
    rating: "",
    review: "",
    load_id: "",
  });

  const openReview = (id) => {
    setRatingForm({
      rating: "",
      review: "",
      load_id: id,
    });
    let el = document.getElementById("openRatingModal");
    el?.click();
  };

  const reviewSubmit = () => {
    loader(true);
    ApiClient.post("reviews", ratingForm).then((res) => {
      if (res.success) {
        toast.success(res.message);
        let el = document.getElementById("openRatingModal");
        el?.click();
        if (TAB == "archive") {
          getExpiredData();
        } else {
          getData();
        }
      }
      loader(false);
    });
  };

  const [expandedRow, setExpandedRow] = useState(-1);
  const toggle = (index) => {
    if (expandedRow === index) {
      setExpandedRow(-1);
    } else {
      setExpandedRow(index);
    }
  };
  const pathname = window.location.pathname;

  useEffect(() => {
    setViewStatus(
      pathname == "/available-loads"
        ? "Available Bids"
        : pathname == "/pendingPickup-loads"
          ? " Pending-Pickup Loads"
          : pathname == "/intransit-loads"
            ? " In-Transit"
            : pathname == "/delivered-loads"
              ? "Delivered"
              : pathname == "/pickedUp-loads"
                ? " PickedUp Loads"
                : "Loads"
    );

    setExpandedRow(-1);
  }, [pathname]);
  const [DesitinationStates, setrDestinationStates] = useState([]);
  const [OriginStates, setOriginStates] = useState([]);
  const [DestinationCities, setDestinationCities] = useState([]);
  const [OriginCities, setOriginCities] = useState([]);
  const StateGetter = (type = "") => {
    if (type == "origin") {
      ApiClient.get("load/origin/state?page=1&count=100").then((res) => {
        if (res.success) {
          setOriginStates(
            res?.data?.data?.map((item) => ({
              id: item?.state,
              name: item?.state,
            }))
          );
        }
      });
    } else {
      ApiClient.get("load/destination/state?page=1&count=100").then((res) => {
        if (res.success) {
          setrDestinationStates(
            res?.data?.data?.map((item) => ({
              id: item?.state,
              name: item?.state,
            }))
          );
        }
      });
    }
  };

  const CitiesGetter = (type = "", filters = {}) => {
    if (type == "origin") {
      let newfilters = {
        ...OriginDestFilters,
        ...filters,
        page: 1,
        count: 300,
      };
      let NewFilters = {
        ...newfilters,
        state: newfilters?.state?.toString(),
        origin_location_city: newfilters?.origin_location_city?.toString(),
        origin_location_state: newfilters?.origin_location_state?.toString(),
        destination_location_city:
          newfilters?.destination_location_city?.toString(),
        destination_location_state:
          newfilters?.destination_location_state?.toString(),
      };

      ApiClient.get("load/origin/city-state", NewFilters).then((res) => {
        if (res.success) {
          setOriginCities(
            res?.data?.data?.map((item) => ({
              id: item?.city,
              name: item?.city,
            }))
          );
        }
      });
    } else {
      let newfilters = {
        ...OriginDestFilters,
        ...filters,
        page: 1,
        count: 300,
      };
      let NewFilters = {
        ...newfilters,
        state: newfilters?.state?.toString(),
        origin_location_city: newfilters?.origin_location_city?.toString(),
        origin_location_state: newfilters?.origin_location_state?.toString(),
        destination_location_city:
          newfilters?.destination_location_city?.toString(),
        destination_location_state:
          newfilters?.destination_location_state?.toString(),
      };
      ApiClient.get("load/destination/city-state", NewFilters).then((res) => {
        if (res.success) {
          setDestinationCities(
            res?.data?.data?.map((item) => ({
              id: item?.city,
              name: item?.city,
            }))
          );
        }
      });
    }
  };

  useEffect(() => {
    StateGetter();
    StateGetter("origin");
    CitiesGetter();
    CitiesGetter("origin");
  }, []);

  useEffect(() => {
    if (id) {
      ApiClient.get(`board?id=${id}`).then((res) => {
        if (res.success) {
          setBoardName(res?.data);
        }
      });
    }
  }, [id]);

  const clearFilter = () => {
    const p = {
      destination_location_city: "",
      destination_location_state: "",
      origin_location_city: "",
      origin_location_state: "",
      // board_id:""
    };
    // setBoardFilter(p)
    setOriginDestFilters(p);
    getData({ ...p });
    CitiesGetter("", { ...p });
    CitiesGetter("origin", { ...p });
  };
  const sampledownload = async () => {
    if(Permission?.load_add || user?.role=="admin")
    {
      try {
        const res = await axios({
          method: "get",
          url: `${environment.api}sample-excel-load-prebook`,
          responseType: "blob",
        });
        var blob = new Blob([res.data], {
          type: res.headers["content-type"],
        });
        let downloadAnchor = document.createElement("a");
        downloadAnchor.href = window.URL.createObjectURL(blob);
        downloadAnchor.download = `${boardName?.name}.xlsx`;
        downloadAnchor.click();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    else
    {
      toast.warn(
        "You do not have valid permission"
      );
    }
   
  };

  const ImportFile = (e) => {
    if(Permission?.load_add || user?.role=="admin")
      {
        loader(true);
        let files = e.target.files;
    
        let file = files?.item(0);
        let url;
        if (id == environment?.rfpsId) {
          url = `import/loads?board_id=${environment?.rfpsId}`;
        } else {
          url = `import/loads?board_id=${id}`;
        }
    
        if (!file) return;
        ApiClient.postFormData(url, { file }).then((res) => {
          if (res.success) {
            Swal.fire({
              // title: "Good job!",
              text: res.message,
              icon: "success",
            });
            // toast.success(res.message);
            getData();
          }
          loader(false);
        });
      }
      else
      {
        toast.warn(
          "You do not have valid permission"
        );
      }
   
  };

  const handleCheck = (e, value, index) => {
    if (e.target.checked) {
      setremoveLoads([...removeLoads, value]);
    } else {
      let filterload = removeLoads.filter((itm) => itm != value);
      setremoveLoads(filterload);
    }
  };
  const AllSelect = (e) => {
    if (e.target.checked) {
      let filterLoads = ListingData.filter((itm) => !itm?.request_status);
      let loadId = filterLoads.map((itm) => {
        return itm?.id;
      });
      setremoveLoads([...loadId]);
    } else {
      setremoveLoads([]);
    }
  };
  const DeleteLoads = () => {
    if( Permission?.load_delete ||
      user?.role == "admin")
      {
        if (removeLoads?.length < 1) {
          toast.error("Please select the load first");
          return;
        }
        const payload = {
          load_ids: removeLoads,
        };
        ApiClient.put(`load/multiple`, payload).then((res) => {
          if (res.success) {
            toast.success(res.message);
            {
              TAB == "list"
                ? getData()
                : getExpiredData();
            }
            setremoveLoads([]);
          }
        });
      }
      else
      {
        toast.warn(
          "You do not have valid permission"
        );
      }
   
  };
  const checklength = () => {
    let filterLoads = ListingData.filter((itm) => !itm?.request_status);
    let loadId = filterLoads.map((itm) => {
      return itm?.id;
    });
    if (
      removeLoads?.length == loadId?.length &&
      (removeLoads?.length != 0 || loadId?.length != 0)
    )
      return true;
    else return false;
  };

  return (
    <>
      <Layout2
        title={
          id ? methodModel.capitalizeFirstLetter(boardName?.name) : viewStatus
        }
        Sidetitle="Loads"
        searchShow="true"
      >
        <div className="mainareas new-table-set">
          <CommonDeleteModal
            show={ShowDeleteModal}
            setShow={setShowDeleteModal}
            confirm={Delete}
          />
          
          <CommonActiveModal
            show={ShowActiveModal}
            setShow={setShowActiveModal}
            confirm={StatusCh}
            status={StatusData.status}
          />
          <div className="common-head-tbl ">
            <div className="row ">
              <div className="col-md-12 ">
                <article className="row filterFlex phView  justify-content-between ">
                  <div className="col-xl-2 mb-sm-2 mb-md-1">
                    <div className="dropdown addDropdown chnagesg  d-flex align-items-center equal-width-50 w-100 mt-0">
                      <div class="bids-top ">
                        <h4>
                          Results<span>{total}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-10 ps-0">
                    <article className="d-flex gap-2 fiftyflex justify-content-end flex-col-c flex-wrap">
                      <div className="">
                        {" "}
                        <div className="dropdown addDropdown   chnagesg  d-flex align-items-center equal-width-50 w-100 ">
                          <button
                            className="btn blck-border-btn dropdown-toggle mb-0 "
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Add Columns
                          </button>
                          <div
                            className="dropdown-menu w-100"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            {findUniqueElements(visibleColumns, columns).map(
                              (itm) => {
                                return (
                                  <a
                                    className={"dropdown-item"}
                                    onClick={() => handleColumnToggle(itm)}
                                  >
                                    {replaceUnderscoresWithSpace(itm)}
                                  </a>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                      {pathname == "/available-loads" ||
                        pathname == "/pendingPickup-loads" ||
                        pathname == "/intransit-loads" ||
                        pathname == "/delivered-loads" ||
                        pathname == "/pickedUp-loads" ? (
                        <></>
                      ) : (
                        <>
                          <div>
                            <button
                              className="btn btn-primary btn-height"
                              onClick={DeleteLoads}
                            >
                              <i className="fa fa-times-circle me-2"></i>Delete
                              Loads
                            </button>
                          </div>
                        </>
                      )}

                      {(user?.role == "admin" || Permission?.load_add) &&
                        id &&
                        !(id == environment?.uspsId) ? (
                        <button
                          className="btn btn-primary btn-height"
                          onClick={() => {
                            Navigate.push(
                              `/loads/add/${methodModel.capitalizeFirstLetter(
                                boardName?.name
                              )}/?board_id=${id}`
                            );
                          }}
                        >
                          <i className="fas fa-plus-circle me-2"></i>
                          Add{" "}
                          {methodModel.capitalizeFirstLetter(boardName?.name)}
                        </button>
                      ) : null}

                      {id && !(id == environment?.uspsId) ? (
                        <>
                          {" "}
                          <button
                            onClick={(e) => sampledownload()}
                            className="btn btn-primary btn-height"
                          >
                            <i className="fa fa-download me-2"></i>Download
                          </button>
                          <div className="result-set">
                            <div className="relative text-center">
                              <label className="cursor-pointer  btn btn-primary btn-height">
                                <i className="fa fa-download me-2"></i>
                                <span>Import</span>
                                <input
                                  id="bannerImage"
                                  type="file"
                                  className="hidden"
                                  accept=".xlsx,.csv"
                                  // value={form.baseImg ? form.baseImg : ""}
                                  onChange={(e) => {
                                    ImportFile(e);
                                  }}
                                />
                              </label>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      <button
                        type="button"
                        class="btn btn-primary btn-height filter-b"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <svg
                          stroke="currentColor"
                          fill="none"
                          stroke-width="2"
                          viewBox="0 0 24 24"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="me-2"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
                        </svg>
                        Filter
                      </button>

                      <div
                        class="modal fade jobs-modal right "
                        id="exampleModal"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLabel">
                                Filters
                              </h5>
                              <button
                                type="button"
                                class="btn-close"
                                id="closemodel"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div class="modal-body">
                              <div className="row">
                                <div className="col-12 mt-3">
                                  <div className="filters-selection">
                                    {pathname == "/loads" ? (
                                      <div className="w-100 wraperpadding mb-3">
                                        <label>Select Board</label>
                                        <div className="multiselect-custom">
                                          <MultiSelectDropdown
                                            id="statusDropdown"
                                            className="role-color"
                                            displayValue="name"
                                            placeholder="Select Load Type"
                                            intialValue={BoardFilter}
                                            result={(e) => {
                                              setBoardFilter(e.value);
                                            }}
                                            options={boards}
                                            required={true}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}

                                    <div className="w-100 wraperpadding mb-3">
                                      <label>Select Destination State</label>
                                      <div className="multiselect-custom">
                                        <MultiSelectDropdown
                                          id="statusDropdown"
                                          className="role-color"
                                          displayValue="name"
                                          placeholder="Select Load Type"
                                          intialValue={
                                            OriginDestFilters?.destination_location_state
                                          }
                                          result={(e) => {
                                            HandleFilter({
                                              destination_location_state:
                                                e.value,
                                              destination_location_city: "",
                                            });
                                            CitiesGetter("", {
                                              state: e?.value,
                                            });
                                            setOriginDestFilters({
                                              destination_location_state:
                                                e.value,
                                              destination_location_city: "",
                                            });
                                          }}
                                          options={DesitinationStates}
                                          required={true}
                                        />
                                      </div>
                                    </div>
                                    <div className="w-100 wraperpadding mb-3">
                                      <label>Select Destination City</label>
                                      <div className="multiselect-custom">
                                        <MultiSelectDropdown
                                          id="statusDropdown"
                                          className="role-color"
                                          displayValue="name"
                                          placeholder="Select Load Type"
                                          intialValue={
                                            OriginDestFilters?.destination_location_city
                                          }
                                          result={(e) => {
                                            HandleFilter({
                                              destination_location_city:
                                                e.value,
                                            });
                                            setOriginDestFilters({
                                              destination_location_city:
                                                e.value,
                                              destination_location_state:
                                                OriginDestFilters?.destination_location_state,
                                            });
                                          }}
                                          key={"Hello"}
                                          options={DestinationCities}
                                          required={true}
                                        />
                                      </div>
                                    </div>
                                    <div className="w-100 wraperpadding mb-3">
                                      <label>Select Origin State</label>
                                      <div className="multiselect-custom">
                                        <MultiSelectDropdown
                                          id="statusDropdown"
                                          className="role-color"
                                          displayValue="name"
                                          placeholder="Select Load Type"
                                          intialValue={
                                            OriginDestFilters?.origin_location_state
                                          }
                                          result={(e) => {
                                            HandleFilter({
                                              origin_location_state: e.value,
                                              origin_location_city: "",
                                            });
                                            CitiesGetter("origin", {
                                              state: e?.value,
                                            });
                                            setOriginDestFilters({
                                              origin_location_state: e.value,
                                              origin_location_city: "",
                                              destination_location_city:
                                                OriginDestFilters?.destination_location_city,
                                              destination_location_state:
                                                OriginDestFilters?.destination_location_state,
                                            });
                                          }}
                                          options={OriginStates}
                                          required={true}
                                        />
                                      </div>
                                    </div>
                                    <div className="w-100 wraperpadding mb-3">
                                      <label>Select Origin City</label>
                                      <div className="multiselect-custom">
                                        <MultiSelectDropdown
                                          id="statusDropdown"
                                          className="role-color"
                                          displayValue="name"
                                          placeholder="Select Load Type"
                                          intialValue={
                                            OriginDestFilters?.origin_location_city
                                          }
                                          result={(e) => {
                                            HandleFilter({
                                              origin_location_city: e.value,
                                            });
                                            setOriginDestFilters({
                                              origin_location_city: e.value,
                                              origin_location_state:
                                                OriginDestFilters?.origin_location_state,
                                              destination_location_city:
                                                OriginDestFilters?.destination_location_city,
                                              destination_location_state:
                                                OriginDestFilters?.destination_location_state,
                                            });
                                          }}
                                          key={"OriginCIty"}
                                          options={OriginCities}
                                          required={true}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-12 ">
                                  <label>Select Loads</label>
                                  <div className="custom-dropdown loads-dropdown">
                                    <ul class="nav nav-tabs portal-tabs portal-tabs-view ">
                                      <li class="nav-item">
                                        <a
                                          class={`nav-link ${TAB == "list"
                                            ? "active bg-primary"
                                            : ""
                                            }`}
                                          aria-current="page"
                                          onClick={() => {
                                            SETTAB("list");
                                            getData();
                                          }}
                                        >
                                          <IoMdCheckmarkCircleOutline className="me-2" />
                                          Active Loads
                                        </a>
                                      </li>
                                      <li
                                        class={`nav-item ${TAB == "archive" ? "hello" : ""
                                          }`}
                                      >
                                        <a
                                          class={`nav-link ${TAB == "archive" ||
                                            filters?.isExpired
                                            ? "active bg-danger"
                                            : ""
                                            }`}
                                          onClick={() => {
                                            SETTAB("archive");
                                            getExpiredData();
                                          }}
                                        >
                                          <AiOutlineExclamationCircle className="me-2" />
                                          Expired Loads
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>

                                <div
                                  className={` pt-4 col-12`}
                                >
                                  <button
                                    className="btn btn-secondary w-100 mb-0 btn-height"
                                    onClick={() => {
                                      clearFilter();
                                    }}
                                  >
                                    Clear
                                  </button>
                                </div>

                                {id == environment?.uspsId ? (
                                  <div className="col-12  pt-4">
                                    <button
                                      className="btn btn-primary w-100 mb-0 btn-height"
                                      onClick={() => {
                                        GetRefreshConnetion();
                                      }}
                                    >
                                      Refresh Connection
                                    </button>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                </article>
              </div>
            </div>
          </div>

         <button type="button" class="btn btn-primary d-none" id="refreshconnection" data-bs-toggle="modal" data-bs-target="#exampleModal1">
            
          </button>
          <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                {/* <div class="modal-header">
                  <h1 class="modal-title fs-5" id="exampleModalLabel">Refresh Connection</h1>
                  
                </div> */}
                <div class="modal-body text-center pt-4">
                 <div className="d-flex justify-content-center align-items-center flex-column gap-3 ">
                 <img src="/assets/img/refresh.png" className="connect_imgs" alt="" />
               <h4 className="connect_title" >  Connection has been refreshed</h4>
                 </div>
                </div>
                <div class="modal-footer border-top-0 pt-0 justify-content-center">
                  <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Ok</button>
                  
                </div>
              </div>
            </div>
          </div> 

          <>
            <div className="table-responsive table_section  new-table">
              <table class="table">
                <thead className="table_head">
                  <tr className="heading_row">
                    {pathname == "/available-loads" ||
                      pathname == "/pendingPickup-loads" ||
                      pathname == "/intransit-loads" ||
                      pathname == "/delivered-loads" ||
                      pathname == "/pickedUp-loads" ? (
                      <></>
                    ) : (
                      <th>
                        <div className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            name="checkbox"
                            value={""}
                            checked={checklength() ? true : false}
                            onChange={(e) => AllSelect(e)}
                            className="form-check"
                          />
                          Select All
                        </div>
                      </th>
                    )}
                    {TAB == "list" ? (
                      <th
                        scope="col"
                        className="table_data pointer"
                        onClick={() => {
                          sorting(
                            "expiration_date",
                            filters?.sorder == "asc" ? "desc" : "asc"
                          );
                        }}
                      >
                        {" "}
                        <span className="d-flex align-items-center">
                          {" "}
                          Time left{" "}
                          {filters?.sortBy === "expiration_date asc" ? (
                            <div class="d-flex  zfonts align-items-center">
                              <div className="d-flex flex-column">
                                <span className="zfont">A </span>{" "}
                                <span className="afont">Z</span>
                              </div>{" "}
                              <span>
                                <span class="material-icons arrfont">
                                  north
                                </span>
                              </span>
                            </div>
                          ) : (
                            <div class="d-flex zfonts align-items-center">
                              <div className="d-flex flex-column">
                                <span className="zfont">Z </span>{" "}
                                <span className="afont">A</span>
                              </div>
                              <span>
                                <span class="material-icons arrfont">
                                  south
                                </span>
                              </span>
                            </div>
                          )}
                        </span>
                      </th>
                    ) : (
                      ""
                    )}
                    <th
                      onClick={() => {
                        sorting(
                          "load_id",
                          filters?.sorder == "asc" ? "desc" : "asc"
                        );
                      }}
                      scope="col"
                      className="table_data pointer"
                    >
                      {" "}
                      <span className="d-flex align-items-center">
                        {" "}
                        Load ID/Lane ID{" "}
                        {filters?.sortBy === "load_id asc" ? (
                          <div class="d-flex  zfonts align-items-center">
                            <div className="d-flex flex-column">
                              <span className="zfont">A </span>{" "}
                              <span className="afont">Z</span>
                            </div>{" "}
                            <span>
                              <span class="material-icons arrfont">north</span>
                            </span>
                          </div>
                        ) : (
                          <div class="d-flex zfonts align-items-center">
                            <div className="d-flex flex-column">
                              <span className="zfont">Z </span>{" "}
                              <span className="afont">A</span>
                            </div>
                            <span>
                              <span class="material-icons arrfont">south</span>
                            </span>
                          </div>
                        )}
                      </span>
                    </th>
                    <th
                      onClick={() => {
                        sorting(
                          "total_distance",
                          filters?.sorder == "asc" ? "desc" : "asc"
                        );
                      }}
                      scope="col"
                      className="table_data pointer"
                    >
                      {" "}
                      <span className="d-flex align-items-center">
                        {" "}
                        Distance (Mi){" "}
                        {filters?.sortBy === "total_distance asc" ? (
                          <div class="d-flex  zfonts align-items-center">
                            <div className="d-flex flex-column">
                              <span className="zfont">A </span>{" "}
                              <span className="afont">Z</span>
                            </div>{" "}
                            <span>
                              <span class="material-icons arrfont">north</span>
                            </span>
                          </div>
                        ) : (
                          <div class="d-flex zfonts align-items-center">
                            <div className="d-flex flex-column">
                              <span className="zfont">Z </span>{" "}
                              <span className="afont">A</span>
                            </div>
                            <span>
                              <span class="material-icons arrfont">south</span>
                            </span>
                          </div>
                        )}
                      </span>
                    </th>
                    <th
                      onClick={() => {
                        sorting(
                          "bid_count",
                          filters?.sorder == "asc" ? "desc" : "asc"
                        );
                      }}
                      scope="col"
                      className="table_data pointer"
                    >
                      {" "}
                      <span className="d-flex align-items-center">
                        {" "}
                        Total Bids{" "}
                        {filters?.sortBy === "bid_count asc" ? (
                          <div class="d-flex  zfonts align-items-center">
                            <div className="d-flex flex-column">
                              <span className="zfont">A </span>{" "}
                              <span className="afont">Z</span>
                            </div>{" "}
                            <span>
                              <span class="material-icons arrfont">north</span>
                            </span>
                          </div>
                        ) : (
                          <div class="d-flex zfonts align-items-center">
                            <div className="d-flex flex-column">
                              <span className="zfont">Z </span>{" "}
                              <span className="afont">A</span>
                            </div>
                            <span>
                              <span class="material-icons arrfont">south</span>
                            </span>
                          </div>
                        )}
                      </span>
                    </th>
                    <th scope="col" className="table_data">
                      Stops
                    </th>
                    {/* <th scope="col" className="table_data pointer">
                  Posted At
                </th> */}

                    {visibleColumns.map((item) => (
                      <th
                        onClick={() => {
                          sorting(
                            ConvertToSortingKeys(item),
                            filters?.sorder == "asc" ? "desc" : "asc"
                          );
                        }}
                        className="text-capitalize table_data "
                      >
                        <span className="d-flex align-items-center">
                          {replaceUnderscoresWithSpace(item)}
                          {item == "origin_zip" ||
                            item == "destination_zip" ||
                            item == "load_start_date" ||
                            item == "load_end_date" ? (
                            <i className="fa fa-sort"></i>
                          ) : (
                            <i aria-hidden="true">
                              {filters?.sorder === "asc" ? (
                                <div class="d-flex  zfonts align-items-center">
                                  <div className="d-flex flex-column">
                                    <span className="zfont">A </span>{" "}
                                    <span className="afont">Z</span>
                                  </div>{" "}
                                  <span>
                                    <span class="material-icons arrfont">
                                      north
                                    </span>
                                  </span>
                                </div>
                              ) : (
                                <div class="d-flex zfonts align-items-center">
                                  <div className="d-flex flex-column">
                                    <span className="zfont">Z </span>{" "}
                                    <span className="afont">A</span>
                                  </div>
                                  <span>
                                    <span class="material-icons arrfont">
                                      south
                                    </span>
                                  </span>
                                </div>
                              )}
                            </i>
                          )}{" "}
                          <i
                            className="fa fa-times"
                            onClick={(e) => handleColumnToggle(item)}
                          ></i>{" "}
                        </span>
                      </th>
                    ))}
                    {url == "/delivered-loads" ? (
                      <th className="table_data">Pickup Time</th>
                    ) : (
                      ""
                    )}

                    {url == "/delivered-loads" ? (
                      <th className="table_data">Delivery Time</th>
                    ) : (
                      ""
                    )}
                    {/* <th scope="col" className="table_data ">
                  Status
                </th> */}

                    <th scope="col" className="table_data">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!loaging &&
                    ListingData &&
                    ListingData.map((itm, i) => {
                      return (
                        <>
                          <tr className="data_row">
                            {pathname == "/available-loads" ||
                              pathname == "/pendingPickup-loads" ||
                              pathname == "/intransit-loads" ||
                              pathname == "/delivered-loads" ||
                              pathname == "/pickedUp-loads" ? (
                              <></>
                            ) : (
                              <td>
                                {itm?.request_status ? <Tooltip title="Bid submitted on this load" placement="top">
                                  <input
                                    type="checkbox"
                                    name="checkbox"
                                    value={itm?.id}
                                    checked={
                                      removeLoads.includes(itm?.id) ? true : false
                                    }
                                    onChange={(e) => handleCheck(e, itm?.id, i)}
                                    disabled={itm?.request_status}
                                    className="form-check"
                                  />
                                </Tooltip> : <input
                                  type="checkbox"
                                  name="checkbox"
                                  value={itm?.id}
                                  checked={
                                    removeLoads.includes(itm?.id) ? true : false
                                  }
                                  onChange={(e) => handleCheck(e, itm?.id, i)}
                                  disabled={itm?.request_status}
                                  className="form-check"
                                />}


                              </td>
                            )}
                            {TAB == "list" ? (
                              <td className="table_dats">
                                <div className="timer d-flex align-items-center">
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 512 512"
                                    class="me-2"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path>
                                  </svg>
                                  <CountdownTimer
                                    expirationDate={itm?.expiration_date}
                                  />
                                </div>
                              </td>
                            ) : (
                              ""
                            )}
                            <td
                              className="table_dats"
                              onClick={() => {
                                // view(itm?.id || itm?._id);
                                if (Permission?.load_get || user?.role=="admin") {
                                    view(itm?.id);
                                }
                              }}
                            >
                              {itm.load_id || itm.lane_id || "--"}
                            </td>
                            <td className="table_dats">{itm.total_distance}</td>
                            <td
                              className="table_dats pointer"
                              onClick={() => {
                                if (user?.role == "admin") {
                                  if (itm?.bid_count != 0) {
                                    document
                                      .getElementById("ViewBidsUser")
                                      .click();
                                    GetBidsUser(itm?.id);
                                    settab("best");
                                  }
                                } else {
                                  if (
                                    itm?.bid_count != 0 &&
                                    Permission?.load_get
                                  ) {
                                    document
                                      .getElementById("ViewBidsUser")
                                      .click();
                                    GetBidsUser(itm?.id);
                                    settab("best");
                                  }
                                }
                              }}
                            >
                              <Tooltip title="View Bid" placement="top">
                                <span className="bid-count">
                                  {itm?.bid_count}
                                </span>
                              </Tooltip>
                            </td>
                            <td>
                              <div
                                className="stops-down-arrow"
                                onClick={() => toggle(i)}
                              >
                                All Stops
                                <i class="fas fa-arrow-circle-down ms-2"></i>
                              </div>
                            </td>

                            {visibleColumns.map((item, index) => (
                              <td className="text-capitalize">
                                {ColumnReturner(item, itm[[item]], itm)}
                              </td>
                            ))}

                            {url == "/delivered-loads" ? (
                              <td>
                                {moment(itm?.pickedup_at).format(
                                  "DD-MM-YYYY h:mm A"
                                ) || "--"}
                              </td>
                            ) : null}

                            {url == "/delivered-loads" ? (
                              <td>
                                {moment(itm?.delivered_at).format(
                                  "DD-MM-YYYY h:mm A"
                                ) || "--"}
                              </td>
                            ) : null}
                            {/* dropdown */}

                            <td className="table_dats">
                              <div className="action_icons">
                                {/* {Permission?.load_delete ||
                                  user?.role == "admin" ? (
                                  <>
                                    <Tooltip placement="top" title="Delete">
                                      <span
                                        className="edit_icon"
                                        
                                        onClick={() => {
                                          if (
                                            Permission?.load_delete ||
                                            user?.role == "admin"
                                          ) {
                                            setDeleteId(itm?.id);
                                            setShowDeleteModal("block");
                                            // deleteLoad(itm?.id)
                                          } else {
                                            toast.warn(
                                              "You do not have valid permission"
                                            );
                                          }
                                        }}
                                      >
                                          <i
                                          class="material-icons delete"
                                          
                                        >
                                          {" "}
                                          delete
                                        </i>
                                      </span>
                                    </Tooltip>
                                  </>
                                ) : (
                                  <></>
                                )} */}

                                {Permission?.load_edit ||
                                  user?.role == "admin" ? (
                                  <Tooltip placement="top" title="View">
                                    <span
                                      className="edit_icon"
                                      onClick={() => {
                                        if (
                                          Permission?.load_edit ||
                                          user?.role == "admin"
                                        ) {
                                          Navigate.push(
                                            `/loads/view/${itm.id}`
                                          );
                                        } else {
                                          toast.warn(
                                            "You do not have valid permission"
                                          );
                                        }
                                      }}
                                    >
                                      <i className="fa fa-eye"></i>
                                    </span>
                                  </Tooltip>
                                ) : null}

                                {url == "/delivered-loads" &&
                                  !itm?.is_submit_review ? (
                                  <>
                                    {/* <button type="button" onClick={()=>openReview(itm?.id)} className="btn btn-sm btn-primary">Add Review</button> */}
                                    <Tooltip placement="top" title="Add Review">
                                      {" "}
                                      <div
                                        class="material-icons reviewIcon"
                                        onClick={() => openReview(itm?.id)}
                                      >
                                        add_comment
                                      </div>{" "}
                                    </Tooltip>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </td>
                          </tr>
                          <React.Fragment key={i}>
                            {expandedRow === i && (
                              <tr>
                                <td colSpan="16" className="bg-white">
                                  <div className="stops-inn">
                                    <div className="table_dats">
                                      {/* <div onClick={() => view(itm?.id)} className="badge badge-success common-badge">
                                        All Stops
                                      </div> */}

                                      {/* <div className="ellipses">{itm.stops[0]?.address}</div>
                                      <div className="ellipses">{itm.stops[1]?.address}</div> */}
                                      <table className="table">
                                        <thead>
                                          <th>Stop No.</th>


                                          {itm?.stops?.find((item, index) => item?.location_id) ? <th>Location Id</th> : <></>}


                                          <th>Address</th>
                                        </thead>
                                        <tbody>
                                          {itm?.stops.length > 0 ? (
                                            itm?.stops?.map((item, index) => {
                                              return (
                                                <tr>
                                                  <td>{index + 1}</td>

                                                  {item?.location_id ? (
                                                    <td>
                                                      {item?.location_id}
                                                    </td>
                                                  ) : (
                                                    <></>
                                                  )}

                                                  <td>{item?.address}</td>
                                                </tr>
                                              );
                                            })
                                          ) : (
                                            <tr>
                                              <td colspan="3">
                                                <span className="text-center mx-auto d-block py-4">
                                                  No Data
                                                </span>
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        </>
                      );
                    })}
                </tbody>
              </table>
              {total == 0 && !loaderr ? (
                <div className=" no-data">
                  <img src="/assets/img/no-data.png" />
                  No Data
                </div>
              ) : (
                <></>
              )}
            </div>
          </>



          {!loaging && total > 0 ? (
            <div className="paginationWrapper">
              <div className="d-flex align-items-center">
                <div className="me-2 user-name-color">Show</div>
                {total > 0 && (
                  <div className="dropdown addDropdown chnagesname ">
                    <button
                      className="btn btn-primary dropdown-toggle removeBg"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {filters?.count}
                    </button>
                    <div
                      className="dropdown-menu shadow bg_hover"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 5 });
                        }}
                      >
                        5
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 10 });
                        }}
                      >
                        10
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 20 });
                        }}
                      >
                        20
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 30 });
                        }}
                      >
                        30
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ChangeFilter({ ...filters, count: 40 });
                        }}
                      >
                        40
                      </a>
                    </div>
                  </div>
                )}{" "}
                <div className="ms-2 user-name-color">from {total} Loads</div>
              </div>

              <Pagination
                currentPage={filters.page}
                totalSize={total}
                sizePerPage={filters.count}
                changeCurrentPage={pageChange}
              />
            </div>
          ) : (
            <></>
          )}

          {loaging ? (
            <div className="text-center py-4">
              <img src="/assets/img/loader.gif" className="pageLoader" />
            </div>
          ) : (
            <></>
          )}

          <button
            style={{ display: "none" }}
            type="button"
            id="ViewBidsUser"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal9"
          >
            Launch demo modal
          </button>

          <div
            class="modal fade bid-modal"
            id="exampleModal9"
            tabindex="-1"
            aria-labelledby="exampleModalLabel9"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div style={{ width: "230%" }} class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="exampleModalLabel9">
                    Bids Details
                  </h1>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body col-md-12">
                  <div
                    className={`${AppliedBid?.length > 6
                      ? "bid-scroll"
                      : "bid_scrolll_remove"
                      }`}
                  >
                    <ul
                      class="nav nav-tabs portal-tabs bid-tabs"
                      id="myTab"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation">
                        <button
                          className={`nav-link ${tab == "best" ? "active" : ""
                            }`}
                          onClick={() => settab("best")}
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#home"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          <LuCircleDotDashed className="me-2" />
                          Best Bids
                        </button>
                      </li>

                      <li class="nav-item" role="presentation">
                        <button
                          className={`nav-link ${tab == "other" ? "active" : ""
                            }`}
                          onClick={() => settab("other")}
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#profile"
                          type="button"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                        >
                          {" "}
                          <LuCircleDot className="me-2" />
                          Bids
                        </button>
                      </li>
                    </ul>
                    {bigLoader ? (
                      <>
                        <div className="text-center p-3">Loading...</div>
                      </>
                    ) : (
                      <>
                        <div class="tab-content" id="myTabContent">
                          <div
                            class={`tab-pane fade ${tab == "best" ? "active show" : ""
                              }`}
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            {AppliedBid.filter((itm) => itm.isBest).length ? (
                              <>
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Carrier Name</th>
                                      <th>Origin City</th>
                                      <th>Destination City</th>
                                      <th>Truck Location</th>
                                      <th>Pickup Date</th>
                                      <th>Expiration Date</th>
                                      <th>ETA</th>
                                      {/* <th>Carrier Name</th> */}

                                      <th>Agreed Rate</th>

                                      <th>Actions</th>
                                      {/* <th></th> */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {AppliedBid?.map((itm) =>
                                      itm?.isBest ? (
                                        <tr
                                          key={itm.id}
                                          className={`data_row ${itm.overtime ? "overtime" : ""
                                            }`}
                                        >
                                          <td className="">
                                            <Tooltip
                                              title="Best Bid"
                                              placement="top"
                                            >
                                              {methodModel.capitalizeFirstLetter(
                                                itm?.carrier_name
                                              )}
                                            </Tooltip>
                                          </td>
                                          <td>
                                            {BidDetial?.origin_location_city}
                                          </td>
                                          <td>
                                            {
                                              BidDetial?.destination_location_city
                                            }
                                          </td>
                                          <Tooltip
                                            title={itm?.truck_location}
                                            placement="top"
                                          >
                                            <td>
                                              {itm?.truck_location?.substr(
                                                0,
                                                18
                                              )}
                                            </td>
                                          </Tooltip>
                                          <td>
                                            {datepipeModel.date(
                                              itm?.load_info?.load_start_date
                                            )}
                                          </td>
                                          <td>
                                            {datepipeModel.date(
                                              BidDetial?.expiration_date
                                            )}
                                          </td>
                                          <td>
                                            {datepipeModel.date(itm?.bid_time)}{" "}
                                            {datepipeModel.isotime(
                                              itm?.bid_time
                                            )}
                                          </td>
                                          <td>
                                            ${itm?.carrier_info?.agreed_rate}
                                          </td>

                                          <td className="text-capitalize">
                                            {itm.status == "rejected" ||
                                              itm.status == "accepted" || itm.status == "awarded" ? (
                                              <>
                                                <span
                                                  className={`badge ${itm.status == "accepted"
                                                    ? "badge-success"
                                                    : "badge-danger"
                                                    }`}
                                                >
                                                  {itm.status}
                                                </span>
                                              </>
                                            ) : (
                                              <>
                                                <span
                                                  className="badge badge-success pointer "
                                                  onClick={() => {
                                                    completeBid(itm?.id);
                                                  }}
                                                >
                                                  Accept
                                                </span>
                                                <span
                                                  className="badge badge-danger pointer ms-2"
                                                  onClick={() => {
                                                    rejectBid(itm?.id);
                                                  }}
                                                >
                                                  {" "}
                                                  Reject
                                                </span>
                                              </>
                                            )}
                                          </td>
                                        </tr>
                                      ) : (
                                        <></>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </>
                            ) : (
                              <>
                                <div className="text-center p-3">No Data</div>
                              </>
                            )}
                          </div>
                          <div
                            class={`tab-pane fade ${tab == "other" ? "active show" : ""
                              }`}
                            id="profile"
                            role="tabpanel"
                            aria-labelledby="profile-tab"
                          >
                            {AppliedBid.filter((itm) => !itm.isBest).length ? (
                              <>
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Carrier Name</th>
                                      <th>Origin City</th>
                                      <th>Destination City</th>
                                      <th>Truck Location</th>
                                      <th>Pickup Date</th>
                                      <th>ETA</th>
                                      <th>Expiration Date</th>
                                      <th>Agreed Rate</th>

                                      <th>Actions</th>
                                      {/* <th></th> */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {AppliedBid?.map((itm) => (
                                      <>
                                        {!itm?.isBest && (
                                          <tr
                                            key={itm.id}
                                            className={`data_row ${itm.overtime ? "overtime" : ""
                                              }`}
                                          >
                                            <td className="pointer">
                                              {methodModel.capitalizeFirstLetter(
                                                itm?.carrier_name
                                              )}
                                            </td>
                                            <td>
                                              {BidDetial?.origin_location_city}
                                            </td>
                                            <td>
                                              {
                                                BidDetial?.destination_location_city
                                              }
                                            </td>
                                            <Tooltip
                                              title={itm?.truck_location}
                                              placement="top"
                                            >
                                              <td>
                                                {itm?.truck_location?.substr(
                                                  0,
                                                  18
                                                )}
                                              </td>
                                            </Tooltip>
                                            <td>
                                              {datepipeModel.date(
                                                itm?.load_info?.load_start_date
                                              )}
                                            </td>
                                            <td>
                                              {itm?.eta_value} {itm?.eta_type}
                                            </td>
                                            <td>
                                              {" "}
                                              {datepipeModel.date(
                                                itm?.bid_time
                                              )}{" "}
                                              {datepipeModel.isotime(
                                                itm?.bid_time
                                              )}
                                            </td>

                                            <td>
                                              ${itm?.carrier_info?.agreed_rate}
                                            </td>

                                            <td className="text-capitalize">
                                              {itm.status == "rejected" ||
                                                itm.status == "accepted" || itm?.status == "awarded"? (
                                                <>
                                                  <span
                                                    className={`badge ${itm.status == "accepted"
                                                      ? "badge-success"
                                                      : "badge-danger"
                                                      }`}
                                                  >
                                                    {itm.status}
                                                  </span>
                                                </>
                                              ) : (
                                                <>
                                                  <span
                                                    className="badge badge-success "
                                                    onClick={() => {
                                                      completeBid(itm?.id);
                                                    }}
                                                  >
                                                    Accept
                                                  </span>
                                                  <span
                                                    className="badge badge-danger ms-2"
                                                    onClick={() => {
                                                      rejectBid(itm?.id);
                                                    }}
                                                  >
                                                    {" "}
                                                    Reject
                                                  </span>
                                                </>
                                              )}
                                            </td>
                                          </tr>
                                        )}
                                      </>
                                    ))}
                                  </tbody>
                                </table>
                              </>
                            ) : (
                              <>
                                <div className="text-center p-3">No Data</div>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    id="CloseBidViewModel"
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout2>

      <button
        type="button"
        class="btn btn-primary d-none"
        id="openRatingModal"
        data-bs-toggle="modal"
        data-bs-target="#ratingModal"
      >
        Launch demo modal
      </button>

      <div
        class="modal fade"
        id="ratingModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Rating
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                reviewSubmit();
              }}
            >
              <div class="modal-body">
                <label>Rating</label>
                <div>
                  <div class="rate-area clearfix rate-div">
                    <input
                      type="radio"
                      id="5-star"
                      name="crating"
                      value="5"
                      checked={ratingForm.rating == 5 ? true : false}
                      onChange={(e) => {
                        setRatingForm({
                          ...ratingForm,
                          rating: e.target.value,
                        });
                      }}
                    />
                    <label for="5-star" title="Amazing">
                      5 stars
                    </label>
                    <input
                      type="radio"
                      id="4-star"
                      name="crating"
                      value="4"
                      checked={ratingForm.rating == 4 ? true : false}
                      onChange={(e) => {
                        setRatingForm({
                          ...ratingForm,
                          rating: e.target.value,
                        });
                      }}
                    />
                    <label for="4-star" title="Good">
                      4 stars
                    </label>
                    <input
                      type="radio"
                      id="3-star"
                      name="crating"
                      value="3"
                      checked={ratingForm.rating == 3 ? true : false}
                      onChange={(e) => {
                        setRatingForm({
                          ...ratingForm,
                          rating: e.target.value,
                        });
                      }}
                    />
                    <label for="3-star" title="Average">
                      3 stars
                    </label>
                    <input
                      type="radio"
                      id="2-star"
                      name="crating"
                      value="2"
                      checked={ratingForm.rating == 2 ? true : false}
                      onChange={(e) => {
                        setRatingForm({
                          ...ratingForm,
                          rating: e.target.value,
                        });
                      }}
                    />
                    <label for="2-star" title="Not Good">
                      2 stars
                    </label>
                    <input
                      type="radio"
                      id="1-star"
                      required=""
                      name="crating"
                      value="1"
                      aria-required="true"
                      checked={ratingForm.rating == 1 ? true : false}
                      onChange={(e) => {
                        setRatingForm({
                          ...ratingForm,
                          rating: e.target.value,
                        });
                      }}
                    />
                    <label for="1-star" title="Bad">
                      1 star
                    </label>
                  </div>
                </div>
                <div>
                  <label>Review</label>
                  <textarea
                    className="form-control"
                    value={ratingForm.review}
                    onChange={(e) => {
                      setRatingForm({ ...ratingForm, review: e.target.value });
                    }}
                    required
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  id="closeRatingModal"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-primary">
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Html;
